<template>
<b-dropdown
    class="p-0 new-assignment-toggle"
    style="border: 0px"
    title="Create Assignment"
    toggle-class="text-decoration-none"
    no-caret
>
    <template #button-content>
        <i class="flaticon2-plus kt-font-success" />
    </template>

    <b-dropdown-item
        :to="{
            name: 'TeacherCourseAssignmentCreate',
            params: {
                schoolStaffId,
                schoolEmail
            },
        }"
    >
        <span>Local Assignment</span>
    </b-dropdown-item>
    <b-dropdown-item
        v-if="course.googleCourseId && !isGoogleDisabled"
        :to="{

            name: 'TeacherGoogleCourseAssignmentCreate',
            params: {
                googleCourseId: course['googleCourseId'] || '0',
                schoolStaffId,
                schoolEmail
            },
        }"
    >
        <span>Google Assignment</span>
    </b-dropdown-item>
</b-dropdown>
</template>
<script lang="ts">
import Vue from 'vue';
import { mapState } from 'vuex';

export default Vue.extend({
    name: 'CreateAssignmentButton',
    props: {
        course: {
            type: Object,
            required: true,
        },
        teacher: {
            type: Object,
            required: true,
        },
    },
    computed: {
        ...mapState({
            school: (state) => state.user.school,
        }),
        isGoogleDisabled() {
            const { school } = this;
            return ['District Managed', 'Disabled'].includes(school.googleSetting);
        },
        schoolTermMarkingPeriodId() {
            return this.$store.state.settings.schoolTermMarkingPeriodId;
        },
        schoolStaffId() {
            if (!this.teacher) return 0;
            return this.teacher.schoolStaffId;
        },
        schoolEmail() {
            if (!this.teacher) return '';
            return this.teacher.schoolEmail;
        },
        extCourseSectionId() {
            return this.course.extCourseSectionId;
        },
    },
});
</script>
