var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "b-dropdown",
    {
      staticClass: "p-0 new-assignment-toggle",
      staticStyle: { border: "0px" },
      attrs: {
        title: "Create Assignment",
        "toggle-class": "text-decoration-none",
        "no-caret": "",
      },
      scopedSlots: _vm._u([
        {
          key: "button-content",
          fn: function () {
            return [_c("i", { staticClass: "flaticon2-plus kt-font-success" })]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "b-dropdown-item",
        {
          attrs: {
            to: {
              name: "TeacherCourseAssignmentCreate",
              params: {
                schoolStaffId: _vm.schoolStaffId,
                schoolEmail: _vm.schoolEmail,
              },
            },
          },
        },
        [_c("span", [_vm._v("Local Assignment")])]
      ),
      _vm.course.googleCourseId && !_vm.isGoogleDisabled
        ? _c(
            "b-dropdown-item",
            {
              attrs: {
                to: {
                  name: "TeacherGoogleCourseAssignmentCreate",
                  params: {
                    googleCourseId: _vm.course["googleCourseId"] || "0",
                    schoolStaffId: _vm.schoolStaffId,
                    schoolEmail: _vm.schoolEmail,
                  },
                },
              },
            },
            [_c("span", [_vm._v("Google Assignment")])]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }