var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      key: `key_${_vm.extCourseSectionId}`,
      staticClass: "kt-subheader kt-grid__item",
    },
    [
      _c("div", { staticClass: "kt-container kt-container--fluid pr-0 mr-2" }, [
        _c(
          "div",
          { staticClass: "kt-subheader__main" },
          [
            _vm.course
              ? _c(
                  "h3",
                  {
                    staticClass:
                      "kt-subheader__title text-nowrap text-truncate",
                  },
                  [
                    _vm.calculating
                      ? _c(
                          "div",
                          { staticClass: "syncgrades loading mr-3 ml-1" },
                          [
                            _c("SVGIcon", {
                              staticClass: "kt-svg-icon",
                              attrs: { name: "syncGradesLoader2" },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c("CourseIcon", {
                      staticClass: "mr-3",
                      attrs: { course: _vm.course, size: "sm" },
                    }),
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.course.courseSectionTitle || _vm.course.name
                        ) +
                        " "
                    ),
                  ],
                  1
                )
              : _vm._e(),
            false ? _c("CourseSubHeaderMenu") : _vm._e(),
            _vm.showMarkingPeriodPicker
              ? _c("SubHeaderMarkingPeriodPicker")
              : _vm._e(),
          ],
          1
        ),
        _c("div", { staticClass: "kt-subheader__toolbar" }, [
          _vm.course && _vm.teacher
            ? _c(
                "div",
                { staticClass: "kt-subheader__wrapper" },
                [
                  _vm._t("buttons"),
                  _vm.course.googleCourseId && !_vm.isGoogleDisabled
                    ? _c(
                        "a",
                        {
                          directives: [
                            {
                              name: "b-tooltip",
                              rawName: "v-b-tooltip.hover.bottomleft",
                              modifiers: { hover: true, bottomleft: true },
                            },
                          ],
                          staticClass: "btn kt-subheader__btn-primary btn-icon",
                          class: {
                            "kt-spinner kt-spinner--v2 kt-spinner--center kt-spinner--sm kt-spinner--primary":
                              _vm.refreshing,
                          },
                          attrs: { href: "#", title: "Refresh Google Data" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.refreshGoogle.apply(null, arguments)
                            },
                          },
                        },
                        [
                          !_vm.refreshing
                            ? _c("i", { staticClass: "la la-refresh" })
                            : _vm._e(),
                        ]
                      )
                    : _vm._e(),
                  _vm.googleCourse &&
                  !_vm.isGoogleDisabled &&
                  !_vm.showGoogleMenu
                    ? _c(
                        "a",
                        {
                          directives: [
                            {
                              name: "b-tooltip",
                              rawName: "v-b-tooltip.hover.bottomleft",
                              modifiers: { hover: true, bottomleft: true },
                            },
                          ],
                          staticClass: "btn kt-subheader__btn-google btn-icon",
                          attrs: { href: "#", title: "Open In Google" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.openInGoogle.apply(null, arguments)
                            },
                          },
                        },
                        [_c("i", { staticClass: "socicon-google" })]
                      )
                    : _vm.course.googleCourseId &&
                      !_vm.isGoogleDisabled &&
                      _vm.showGoogleMenu
                    ? _c(
                        "span",
                        [
                          _vm._m(0),
                          _c(
                            "b-popover",
                            {
                              attrs: {
                                target: "googleActions-popover",
                                triggers: "focus",
                                placement: "bottom",
                                "custom-class": "google-actions-popover",
                              },
                              model: {
                                value: _vm.showGoogleMenuToggle,
                                callback: function ($$v) {
                                  _vm.showGoogleMenuToggle = $$v
                                },
                                expression: "showGoogleMenuToggle",
                              },
                            },
                            [
                              _c("ul", { staticClass: "kt-nav" }, [
                                _c("li", { staticClass: "kt-nav__head mb-2" }, [
                                  _vm._v(" Google Actions "),
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-google btn-sm",
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          $event.preventDefault()
                                          return _vm.openInGoogle.apply(
                                            null,
                                            arguments
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v(" Open in Google ")]
                                  ),
                                ]),
                                _c("li", { staticClass: "kt-nav__item" }, [
                                  _c("span", { staticClass: "ml-5 row" }, [
                                    _c(
                                      "a",
                                      {
                                        staticClass:
                                          "btn btn-label-success mr-3",
                                        attrs: { href: "#" },
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            $event.preventDefault()
                                            return _vm.showGoogleRostersModal.apply(
                                              null,
                                              arguments
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "socicon-google",
                                        }),
                                        _vm._v(" Populate Google Rosters "),
                                      ]
                                    ),
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "kt-nav__link-text kt-font-lg mt-2",
                                      },
                                      [
                                        _vm._v("Enrollment Code: "),
                                        _c("code", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.googleCourse.enrollmentCode
                                            )
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]),
                                ]),
                              ]),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("CreateAssignmentButton", {
                    attrs: { course: _vm.course, teacher: _vm.teacher },
                  }),
                  _vm.$slots["additional-links"]
                    ? _c(
                        "CourseSubHeaderSettingsMenu",
                        { attrs: { "export-data": _vm.exportData } },
                        [_vm._t("additional-links")],
                        2
                      )
                    : _vm._e(),
                ],
                2
              )
            : _vm._e(),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      {
        staticClass: "btn kt-subheader__btn-google btn-icon",
        attrs: {
          id: "googleActions-popover",
          href: "#",
          tabindex: "0",
          "data-toggle": "popover",
          "data-trigger": "focus",
          title: "Google Actions",
        },
      },
      [_c("i", { staticClass: "socicon-google" })]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }