<template>
<span>
    <a
        id="settings_menu"
        title="Settings"
        href="#"
        class="btn kt-subheader__btn-secondary btn-icon"
        @click.stop.prevent="toggleMenuPopover"
    >
        <SVGIcon name="more" />
    </a>
    <b-popover
        triggers="focus"
        target="settings_menu"
        placement="bottom"
        :show.sync="show"
        custom-class="menu-popover mt-4"
    >
        <div class="kt-widget4">
            <div
                v-if="exportData"
                class="kt-widget4__item"
            >
                <span class="kt-widget4__icon">
                    <i class="flaticon-download" />
                </span>
                <a
                    href="#"
                    class="kt-widget4__title kt-widget4__title--light"
                    @click.stop.prevent="exportData"
                >
                    <span class="kt-menu__link-text"> Export to CSV file </span>
                </a>
            </div>
            <div
                v-if="googleCourse"
                class="kt-widget4__item"
            >
                <span class="kt-widget4__icon">
                    <SVGIcon
                        hex-color="#0f88ef"
                        name="refresh"
                    />
                </span>
                <a
                    href="#"
                    class="kt-widget4__title kt-widget4__title--light"
                    @click.stop.prevent="refresh()"
                >
                    <span class="kt-menu__link-text">
                        Synchronize Google Data
                    </span>
                </a>
            </div>
            <slot />
            <div
                v-if="googleCourse && googleCourse.alternateLink"
                class="kt-widget4__item"
            >
                <a
                    :href="googleCourse.alternateLink"
                    target="_blank"
                    class="kt-widget4__title kt-widget4__title--light"
                >
                    <span class="kt-menu__link-text">
                        Open Course in Google
                    </span>
                </a>
            </div>

        </div>
    </b-popover>
</span>
</template>
<script lang="ts">

import Vue from 'vue';
import Types from '../store/Types';
import googleCourseMixins from '../store/mixins/googleCourseMixins';

export default Vue.extend({
    name: 'CourseSubHeaderSettingsMenu',
    mixins: [googleCourseMixins],
    props: {
        exportData: {
            type: Function,
            default: undefined,
        },
    },
    data() {
        return {
            key: 0,
            show: false,
        };
    },
    computed: {
        googleCourse() {
            return this.$_googleCourseMixins_getGoogleCourseFromRoute();
        },
    },
    methods: {
        refresh() {
            const { showError, googleCourse } = this;
            if (!googleCourse) return;
            this.$store.dispatch(Types.actions.REFRESH_GOOGLE_COURSE, (err) => {
                if (err) showError(err);
                else window.location.reload();
            });
        },
        toggleMenuPopover() {
            this.show = !this.show;
        },
    },
});

</script>

<style>
.menu-popover .arrow {
    visibility: hidden;
}
</style>

<style scoped>
.menu-popover {
    min-width: 240px;
}
.kt-subheader__btn-daterange .kt-menu__link-text {
    color: #007bff !important;
    font-weight: 500;
}
.kt-subheader__btn-secondary .kt-menu__link-text {
    color: #434349 !important;
}

.kt-menu__solo {
    color: #434349;
    font-weight: 400;
}

.kt-menu__solo:hover {
    color: #007bff !important;
}
</style>
