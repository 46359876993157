<template>
<div
    :key="`id_${key}`"
    class="kt-header-menu-wrapper ml-0"
>
    <div class="kt-header-menu kt-header-menu-mobile kt-header-menu--layout-default ml-0">
        <ul class="kt-menu__nav">
            <li
                v-for="section in sections"
                :key="`section_${section.title}`"
                class="kt-menu__item kt-menu__item--submenu"
                :class="section.show ? 'kt-menu__item--active' : 'kt-menu__item--hover'"
            >
                <a
                    v-if="section.items"
                    :id="`link_${section.title}`"
                    ref="menu-link"
                    href="#"
                    class=""
                    :class="section.show ? 'btn kt-subheader__btn-daterange' : 'btn kt-subheader__btn-secondary kt-menu__link'"
                    @click.stop.prevent="toggleSection(section.title)"
                >
                    <span class="kt-menu__link-text">
                        {{ section.title }}
                    </span>
                </a>
                <router-link
                    v-else-if="section.route"
                    :to="section.route"
                    class="kt-widget4__title kt-widget4__title--light kt-menu__link"
                >
                    <span class="kt-menu__solo">
                        {{ section.title }}
                    </span>
                </router-link>
            </li>
        </ul>
    </div>
    <div
        v-for="section in sections"
        :key="`pop_${section.title}`"
    >
        <b-popover
            v-if="section.items"
            triggers="focus"
            :target="`link_${section.title}`"
            placement="bottom"
            :show.sync="section.show"
            custom-class="menu-popover mt-4"
        >
            <div class="kt-widget4">
                <div
                    v-for="item in section.items"
                    :key="`item_${item.title}`"
                    class="kt-widget4__item"
                >
                    <span class="kt-widget4__icon">
                        <SVGIcon
                            hex-color="#0f88ef"
                            :name="item.icon"
                        />
                    </span>
                    <router-link
                        v-if="item.route"
                        :to="item.route"
                        class="kt-widget4__title kt-widget4__title--light"
                    >
                        <i class="kt-menu__link-bullet kt-menu__link-bullet--dot"><span /></i>
                        <span class="kt-menu__link-text">
                            {{ item.title }}
                        </span>
                    </router-link>
                    <span class="d-none kt-widget4__number kt-font-info">
                        +500
                    </span>
                </div>
            </div>
        </b-popover>
    </div>
</div>
</template>
<script lang="ts">

import Vue from 'vue';

export default Vue.extend({
    name: 'CourseSubHeaderMenu',
    data() {
        return {
            key: 0,
            sections: [{
                title: 'Grades',
                show: false,
                items: [{
                    courseRouteName: 'CourseGradebook',
                    teacherRouteName: 'TeacherCourseGradebook',
                    title: 'Gradebook',
                    icon: 'gradebook',
                    route: null, // calculated at runtime
                }, {
                    courseRouteName: 'CourseAverages',
                    teacherRouteName: 'TeacherCourseAverages',
                    title: 'Averages',
                    icon: 'averages',
                    route: null,
                }, {
                    courseRouteName: 'CourseReportCards',
                    teacherRouteName: 'TeacherCourseReportCards',
                    title: 'Report Card Grades',
                    icon: 'reportCards',
                }, {
                    courseRouteName: 'CourseReports',
                    teacherRouteName: 'TeacherCourseReports',
                    title: 'Reports',
                    icon: 'progressReports',
                    route: null,
                }],
            }, {
                title: 'Assignments',
                show: false,
                items: [{
                    courseRouteName: 'CourseAssignments',
                    teacherRouteName: 'TeacherCourseAssignments',
                    title: 'Assignments',
                    icon: 'assignments',
                    route: null,
                }, {
                    courseRouteName: 'CourseAssignmentCreate',
                    teacherRouteName: 'TeacherCourseAssignmentCreate',
                    title: 'New Assignment',
                    icon: 'createAssignment',
                    route: null,
                }],
            }, {
                title: 'Attendance',
                show: false,
                courseRouteName: 'CourseAttendance',
                teacherRouteName: 'TeacherCourseAttendance',
                icon: 'attendance',
                route: null,
            }],
        };
    },
    watch: {
        '$route.params.extCourseSectionId': {
            handler() {
                this.populate();
            },
        },
        '$route.params.schoolEmail': {
            handler() {
                this.populate();
            },
        },
    },
    mounted() {
        this.populate();
    },
    methods: {
        toggleSection(title) {
            this.sections.map((s) => {
                const section = s;
                if (s.title === title) {
                    section.show = !s.show;
                    return section;
                }
                section.show = false;
                return section;
            });
        },
        populate() {
            // adds vue router into to menu
            const { extCourseSectionId, schoolEmail } = this.$route.params;
            this.sections = this.sections.map((sec) => {
                const section = sec;
                if (section.items) {
                    section.items = section.items.map((i) => {
                        const item = i;
                        item.route = {
                            name: i.courseRouteName,
                            params: { extCourseSectionId },
                        };
                        if (schoolEmail) {
                            item.route = {
                                name: i.teacherRouteName,
                                params: { extCourseSectionId, schoolEmail },
                            };
                        }
                        return item;
                    });
                } else {
                    section.route = {
                        name: section.courseRouteName,
                        params: { extCourseSectionId },
                    };
                    if (schoolEmail) {
                        section.route = {
                            name: section.teacherRouteName,
                            params: { extCourseSectionId, schoolEmail },
                        };
                    }
                }
                return section;
            });
            this.key += 1;
        },
    },
});

</script>

<style>

.menu-popover .arrow {
    visibility: hidden;
}
</style>

<style scoped>
.menu-popover {
    min-width: 240px;
}
.kt-subheader__btn-daterange .kt-menu__link-text {
    color: #007bff !important;
    font-weight: 500;
}
.kt-subheader__btn-secondary .kt-menu__link-text {
    color: #434349 !important;
}

.kt-menu__solo {
    color: #434349;
    font-weight: 400;
}

.kt-menu__solo:hover {
    color: #007bff !important;
}
</style>
