var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "span",
    [
      _c(
        "a",
        {
          staticClass: "btn kt-subheader__btn-secondary btn-icon",
          attrs: { id: "settings_menu", title: "Settings", href: "#" },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              $event.preventDefault()
              return _vm.toggleMenuPopover.apply(null, arguments)
            },
          },
        },
        [_c("SVGIcon", { attrs: { name: "more" } })],
        1
      ),
      _c(
        "b-popover",
        {
          attrs: {
            triggers: "focus",
            target: "settings_menu",
            placement: "bottom",
            show: _vm.show,
            "custom-class": "menu-popover mt-4",
          },
          on: {
            "update:show": function ($event) {
              _vm.show = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "kt-widget4" },
            [
              _vm.exportData
                ? _c("div", { staticClass: "kt-widget4__item" }, [
                    _c("span", { staticClass: "kt-widget4__icon" }, [
                      _c("i", { staticClass: "flaticon-download" }),
                    ]),
                    _c(
                      "a",
                      {
                        staticClass:
                          "kt-widget4__title kt-widget4__title--light",
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            $event.preventDefault()
                            return _vm.exportData.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _c("span", { staticClass: "kt-menu__link-text" }, [
                          _vm._v(" Export to CSV file "),
                        ]),
                      ]
                    ),
                  ])
                : _vm._e(),
              _vm.googleCourse
                ? _c("div", { staticClass: "kt-widget4__item" }, [
                    _c(
                      "span",
                      { staticClass: "kt-widget4__icon" },
                      [
                        _c("SVGIcon", {
                          attrs: { "hex-color": "#0f88ef", name: "refresh" },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "a",
                      {
                        staticClass:
                          "kt-widget4__title kt-widget4__title--light",
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            $event.preventDefault()
                            return _vm.refresh()
                          },
                        },
                      },
                      [
                        _c("span", { staticClass: "kt-menu__link-text" }, [
                          _vm._v(" Synchronize Google Data "),
                        ]),
                      ]
                    ),
                  ])
                : _vm._e(),
              _vm._t("default"),
              _vm.googleCourse && _vm.googleCourse.alternateLink
                ? _c("div", { staticClass: "kt-widget4__item" }, [
                    _c(
                      "a",
                      {
                        staticClass:
                          "kt-widget4__title kt-widget4__title--light",
                        attrs: {
                          href: _vm.googleCourse.alternateLink,
                          target: "_blank",
                        },
                      },
                      [
                        _c("span", { staticClass: "kt-menu__link-text" }, [
                          _vm._v(" Open Course in Google "),
                        ]),
                      ]
                    ),
                  ])
                : _vm._e(),
            ],
            2
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }