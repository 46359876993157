<template>
<div
    :key="`key_${extCourseSectionId}`"
    class="kt-subheader kt-grid__item"
>
    <div class="kt-container kt-container--fluid pr-0 mr-2">
        <div class="kt-subheader__main">
            <h3 v-if="course" class="kt-subheader__title text-nowrap text-truncate">
                <div
                    v-if="calculating"
                    class="syncgrades loading mr-3 ml-1"
                >
                    <SVGIcon
                        class="kt-svg-icon"
                        name="syncGradesLoader2"
                    />
                </div>
                <CourseIcon
                    :course="course"
                    size="sm"
                    class="mr-3"
                />
                {{ course.courseSectionTitle || course.name }}
            </h3>

            <CourseSubHeaderMenu v-if="false" />
            <SubHeaderMarkingPeriodPicker v-if="showMarkingPeriodPicker" />
        </div>

        <div class="kt-subheader__toolbar">
            <div
                v-if="course && teacher"
                class="kt-subheader__wrapper"
            >
                <slot name="buttons" />
                <a
                    v-if="course.googleCourseId && !isGoogleDisabled"
                    v-b-tooltip.hover.bottomleft
                    href="#"
                    title="Refresh Google Data"
                    class="btn kt-subheader__btn-primary btn-icon"
                    :class="{'kt-spinner kt-spinner--v2 kt-spinner--center kt-spinner--sm kt-spinner--primary': refreshing}"
                    @click.stop.prevent="refreshGoogle"
                >

                    <i
                        v-if="!refreshing"
                        class="la la-refresh"
                    />
                </a>

                <a
                    v-if="googleCourse && !isGoogleDisabled && !showGoogleMenu"
                    v-b-tooltip.hover.bottomleft
                    href="#"
                    title="Open In Google"
                    class="btn kt-subheader__btn-google btn-icon"
                    @click.stop.prevent="openInGoogle"
                >
                    <i class="socicon-google" />
                </a>
                <span v-else-if="course.googleCourseId && !isGoogleDisabled && showGoogleMenu">
                    <a
                        id="googleActions-popover"
                        href="#"
                        tabindex="0"
                        data-toggle="popover"
                        data-trigger="focus"
                        title="Google Actions"
                        class="btn kt-subheader__btn-google btn-icon"
                    >
                        <i class="socicon-google" />
                    </a>
                    <b-popover
                        v-model:show="showGoogleMenuToggle"
                        target="googleActions-popover"
                        triggers="focus"
                        placement="bottom"
                        custom-class="google-actions-popover"
                    >
                        <ul class="kt-nav">
                            <li class="kt-nav__head mb-2">
                                Google Actions
                                <button
                                    class="btn btn-google btn-sm"
                                    @click.stop.prevent="openInGoogle"
                                >
                                    Open in Google
                                </button>
                            </li>
                            <li class="kt-nav__item">
                                <span class="ml-5 row">
                                    <a
                                        href="#"
                                        class="btn btn-label-success mr-3"
                                        @click.stop.prevent="showGoogleRostersModal"
                                    >
                                        <i class="socicon-google" />
                                        Populate Google Rosters
                                    </a>
                                    <span class="kt-nav__link-text kt-font-lg mt-2">Enrollment Code:
                                        <code>{{ googleCourse.enrollmentCode }}</code>
                                    </span>
                                </span>
                            </li>
                        </ul>
                    </b-popover>
                </span>

                <CreateAssignmentButton
                    :course="course"
                    :teacher="teacher"
                />

                <CourseSubHeaderSettingsMenu
                    v-if="$slots['additional-links']"
                    :export-data="exportData"
                >
                    <slot name="additional-links" />
                </CourseSubHeaderSettingsMenu>
            </div>
        </div>
    </div>
</div>
</template>

<script lang="ts">
import CourseSubHeaderMenu from './CourseSubHeaderMenu.vue';
import courseMixins from '../store/mixins/courseMixins';
import googleCourseMixins from '../store/mixins/googleCourseMixins';
import Types from '../store/Types';
import userMixins from '../store/mixins/userMixins';
import teacherMixins from '../store/mixins/teacherMixins';
import CourseIcon from '../components/CourseIcon.vue';
import CreateAssignmentButton from '../components/CreateAssignmentButton.vue';
import CourseSubHeaderSettingsMenu from './CourseSubHeaderSettingsMenu.vue';
import SubHeaderMarkingPeriodPicker from '../components/SubHeaderMarkingPeriodPicker.vue';
import SVGIcon from '../components/SVGIcon/SVGIcon.vue';

export default {
    name: 'CourseSubHeader',
    components: {
        CourseIcon,
        CreateAssignmentButton,
        CourseSubHeaderMenu,
        SubHeaderMarkingPeriodPicker,
        CourseSubHeaderSettingsMenu,
        SVGIcon,
    },
    mixins: [
        userMixins,
        courseMixins,
        googleCourseMixins,
        teacherMixins,
    ],
    props: {
        exportData: {
            type: Function,
            required: false,
            default: undefined,
        },
        incrementKey: {
            type: Function,
            required: false,
            default: undefined,
        },
        showMarkingPeriodPicker: {
            type: Boolean,
            required: false,
            default: false,
        },
        showGoogleMenu: {
            type: Boolean,
            required: false,
            default: true,
        },
        showGoogleRostersModal: {
            type: Function,
            required: false,
            default: () => {},
        },
    },
    data() {
        return {
            refreshing: false,
            showGoogleMenuToggle: false,
        };
    },
    computed: {
        user() {
            return this.$_userMixins_user;
        },
        calculating() {
            const { cache } = this.$store.state.database;
            return cache.find((k) => k.status == 'loading' && k.cacheType == 'portfolioCalc') || null;
        },
        isGoogleDisabled() {
            const { user } = this;
            const { school } = user;
            return ['District Managed', 'Disabled'].includes(school.googleSetting);
        },
        teacher() {
            if (!this.schoolEmail) return null;
            return this.$_teacherMixins_getTeacherFromEmail(this.schoolEmail);
        },
        course() {
            if (!this.extCourseSectionId) return null;
            return this.$_courseMixins_getCourseFromRoute();
        },
        googleCourse() {
            return this.$_googleCourseMixins_getGoogleCourseFromRoute();
        },
        schoolEmail() {
            return this.$route.params.schoolEmail;
        },
        extCourseSectionId() {
            return this.$route.params.extCourseSectionId;
        },
        title() {
            const route = this.$route;
            return route.meta.title || null;
        },
        icon() {
            const route = this.$route;
            return route.meta.icon || null;
        },
    },
    watch: {
        extCourseSectionId() {
            this.key += 1;
        },
    },
    methods: {
        refreshGoogle() {
            const { showError, incrementKey } = this;
            this.refreshing = true;
            this.$store.dispatch(Types.actions.REFRESH_GOOGLE_COURSE, (err) => {
                this.refreshing = false;
                if (incrementKey) incrementKey();
                if (err) return showError(err);
            });
        },
        openInGoogle() {
            if (!this.course || !this.googleCourse) return;
            const { googleCourse } = this;
            window.open(googleCourse.alternateLink);
        },
    },
};

</script>

<style scoped>
h3.kt-subheader__title {
    max-width: 260px;
}
.syncgrades {
  transition-property: transform;
  transition-duration: 1s;
}
.syncgrades.loading {
  animation-name: rotate;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>

<style>
.new-assignment-toggle button {
    border: none !important;
    padding: 0 4px 0 10px;
    margin: 0;
}

</style>
